@import "../../_globalColor";

.projects-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  grid-auto-rows: minmax(100px, auto);
  grid-gap: 1rem 1rem;
  gap: 1rem 1rem;
  padding: 20px;
}

/* For viewports larger than 580px, make it 2 columns */
@media (min-width: 580px) {
  .projects-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* For viewports smaller than 580px, make it 1 column */
@media (max-width: 579px) {
  .projects-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

.heading {
  width: 90%;
  margin: 50px auto 20px auto;
  font-size: 56px;
  font-weight: 400;
}
/* Media Query */

@media (max-width: 1380px) {
  .heading {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 30px;
    text-align: center;
  }
}
