@import "../../_globalColor";


.frontside,
.backside {
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 30px;


    img {
        width: 250px;
        border-radius: 30px;
    }
}

.flippy-container {
    height: 100%;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    line-height: normal;
}

.frontside:hover,
.backside:hover {
    box-shadow: $lightBoxShadowDark 0px 20px 30px -10px;
    background-color: $buttonColor !important;
    box-shadow: 0px 0px 16px $darkBoxShadow;
}


.backside li {
    margin: 10px 0;
}