@import "../_globalColor";

.dark-mode {
  background-color: $darkBackground;
  color: white;
  transition: "0.1s";
}

.max-width {
  margin: auto;
  
  max-width: 1600px;
}